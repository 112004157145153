import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MoreItems } from '../../../models/more-dropdown.config';
import { IconComponent } from '../../layout/widgets/icon/icon.component';

@Component({
  selector: 'app-more-dropdown',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './more-dropdown.component.html',
  styleUrl: './more-dropdown.component.scss',
})
export class MoreDropdownComponent {
  @Input() enableItems: MoreItems[] = [];
  @Output() templateClick = new EventEmitter<void>();
  @Output() importClick = new EventEmitter<void>();
  @Output() exportClick = new EventEmitter<void>();
  @Output() archiveClick = new EventEmitter<void>();
  @Output() deleteClick = new EventEmitter<void>();
  @Output() refreshClick = new EventEmitter<void>();

  performAction(action: string) {
    switch (action) {
      case 'template':
        this.templateClick.emit();
        break;
      case 'import':
        this.importClick.emit();
        break;
      case 'export':
        this.exportClick.emit();
        break;
      case 'archive':
        this.archiveClick.emit();
        break;
      case 'delete':
        this.deleteClick.emit();
        break;
      case 'refresh':
        this.refreshClick.emit();
        break;
      default:
        break;
    }
  }
}
