<div class="dropdown more-dropdown btn-grey me-2">
  <button class="btn btn-secondary dropdown-toggle btn-grey" type="button" data-bs-toggle="dropdown">
    More
  </button>
  <ul class="dropdown-menu" aria-labelledby="more-dropdown-button">
    @if (enableItems.includes('template')) {
      <li>
        <a class="dropdown-item" (click)="performAction('template')">
          <app-icon icon="template-action" class="me-1"></app-icon>Template
        </a>
      </li>
    }
    @if (enableItems.includes('import')) {
      <li>
        <a class="dropdown-item" (click)="performAction('import')">
          <app-icon icon="import-action" class="me-1"></app-icon>Import
        </a>
      </li>
    }
    @if (enableItems.includes('export')) {
      <li>
        <a class="dropdown-item" (click)="performAction('export')">
          <app-icon icon="export-action" class="me-1"></app-icon>Export
        </a>
      </li>
    }
    @if (enableItems.includes('archive')) {
      <li>
        <a class="dropdown-item" (click)="performAction('archive')">
          <app-icon icon="archive-action" class="me-1"></app-icon>Archive
        </a>
      </li>
    }
    @if (enableItems.includes('delete')) {
      <li>
        <a class="dropdown-item" (click)="performAction('delete')">
          <app-icon icon="delete-action" class="me-1"></app-icon>Delete
        </a>
      </li>
    }
    @if (enableItems.includes('refresh')) {
      <li>
        <a class="dropdown-item" (click)="performAction('refresh')">
          <app-icon icon="refresh-action" class="me-1"></app-icon>Refresh
        </a>
      </li>
    }
  </ul>
</div>
